import React,  { useState, useRef  } from 'react';
import '../css/commencsspage.css';
import { Routes, Route, Link ,useNavigate} from 'react-router-dom';
import mainimagecomman from '../images/widekabbaimage.jpg';
import airticket1 from '../images/services/airticket.png';
import buffet from '../images/services/buffet.png';
import bus from '../images/services/bus.png';
import hotel from '../images/services/hotel.png';
import insurance from '../images/services/insurance.png';
import laundry from '../images/services/laundry.png';
import travelbag from '../images/services/travelbag.png';
import visa from '../images/services/visa.png';
import zamzam from '../images/services/zamzam.png';
import ziyarath from '../images/services/ziyarath.png';
import travelimage from '../images/travelimage.jpg';
import aqsa from '../images/aqsa.jpg';
import aqsa2 from '../images/aqsa2.jpg';
import ziyaratpackage from '../images/ziyaratpackage.jpg';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const ZiyarathCostlyPage = () => {
  const [ziyarathcostlyStates, setZiyarathCostlyStates] = useState({
    pointtoremember: false,
    termsandconditions: false,
    inclusionjordan: false,
    inclusionjerusalem: false,
    exclusion:false,
    // Add more dropdown keys as needed
  });

  const toggleDropdown = (dropdown) => {
    setZiyarathCostlyStates((prevState) => ({
      ...prevState,
      [dropdown]: !prevState[dropdown],
    }));
  };

  const closeDropdown = (dropdown) => {
    setZiyarathCostlyStates((prevState) => ({
      ...prevState,
      [dropdown]: false,
    }));
  };
    return (
      <main className="commanPageContainer" >
          <div className="commanPageImageContainer">
            <img src={aqsa} alt="no image found"></img>
            <div className="commanheader"><h1>Ziyarat</h1>
            <p>Embark on a sacred pilgrimage to the holiest sites with our enriching Ziyarath tours, where faith and history converge.</p>
            </div>
          </div>
         
          <div className="commanPageContentContainer">
          
                
               
            <div className='commanPageMainContainer'>
                
            <h1>Masjid Al Aqsa-Bait Al Muqaddas
Holy Land Tour 2024</h1>
            
            <h3>Ziyarath PACKAGE FROM BANGALORE 2024</h3>
            <p>The meaning of the name ‘Masjid al-Aqsa’ is ‘the farthest mosque’ and to Muslims is the third most holiest place in Islam. This mosque is well known for the visit of Prophet Muhammed(PBUH.) in 621 CE. This visit is known as the night journey from Makkah, riding on the Buraq.</p>
            <p>Masjid al-Aqsa is a very special masjid. The Prophet (PBUH) on many occasions explained to the Sahabah (Companions) to appreciate the excellent qualities of Masjid al-Aqsa.</p>
            <p>Prophet Muhammad (saw) taught us that we should only undertake a special journey to one of three masaajid; Al Masjid Al Haram in Makkah, Al Masjid An-Nabawi in Madinah, and Al Masjid Al Aqsa in Jerusalem. In addition – prayer in each of these blessed masaajid are multiplied in virtue, with one salaah in Al Masjid Al Aqsa receiving at least 500 times the reward of salaah elsewhere.</p>
            
            <h3>Recommendations:</h3>
            {/* <div className="recommended">
              
              <div className="rpackage"></div>
              <div className="rpackage"></div>
              <div className="rpackage"></div>
            </div> */}
            <a href="/ziyaratheconomypackpage">Economy Baghdad Ziyarat Package 2024</a>
            <a href="/umrahpage">Umrah</a>
            <a href="/hajjPage">Hajj</a>
            <p><strong>Departure Cities:</strong>Bangalore</p>
            <span className="theline"></span>
           
            
            <h3>Departure:</h3>
            <p><strong>DATE (BOM) :</strong>Every Month Last</p>

            {/* <span className="theline"></span> */}
            <div className="purchaseBanner"><span className="purchaseSpan"><strong>Pre-Book</strong></span></div>
            <p><strong>Suggested Day Wise Itinerary:</strong></p>
            <p><strong>6 DAYS 5 NIGHTS HOLY LAND TOUR</strong></p>
            <p><strong>DAY 01: AMMAN AIRPORT - AMMAN ISLAMIC TOUR(AS-HAB ALKAHEF-ABD ALRAHMAN BIN OWF )–AMMAN CITY TOUR ( CITADEL- ROMAN THEATRE)- AMMAN HOTEL FOR DINNER & OVERNIGHT</strong> After breakfast, visit the Cave of the Seven Sleepers (Ashab al Kahf), which is mentioned in a Sura in the Qur'an. This is where a group of devout Christian youths sought refuge from prosecution by the tyrannical Roman Emperor Trajan for monotheism. To keep them safe, Allah put them to sleep. They awoke 309 lunar years later; however, by then, Christianity was widespread, so Allah put them to however, by then, Christianity was widespread, so Allah put them to sleep for eternity in the cave. The Byzantine ruins and mosques nearby are accurately described in the Holy Qur'an. We then drive to the tomb of the venerable companion Abdul Rahman bin Auf. He was among the first eight people to accept Islam. A line of stones marks the spot where he was buried. He is considered one of the "Blessed Ten" who Prophet Mohammad. Back into the centre of Amman for a city tour, including a visit to The Citadel, the site of the oldest civilization in Amman, the old downtown area and finally to the King Abdullah Mosque for prayer. Return to your hotel. Dinner & Overnight: Amman</p>
            <p><strong>DAY 02: AMMAN HOTEL – PROPHET SHUAIB TOMB- PROPHET YOUSHA- COMPANION ABU OBADIAH – DERAR BIN AZUAR – SHARHABEEL BIN HASNAH</strong> After having your breakfast at the hotel, we will visit the claimed tomb of Prophet Shuaib. Which is located in the Balqa region. Shuaib is known to be one of the few Arabian Prophets mentioned in the Qur’an. Then we will Visit Makam Prophet Yosha Bin Noon (Upon Him Be Peace) who Was Very Close To Musa (Upon Him Be Peace) And Took His Place As The Leader Of The Bani Israil After His Death. He Is Known As joshua In The Bible. Yusha (Upon Him Be Peace) Was The Great Grandson Of Prophet Yusuf (Upon Him Be Peace),, then We Drive To Jordan Valley to Visit Makamat Companions Abu Obaidah Amer Bin jjarah Was One Of Companions Of The Islamic Prophet Muhammad. He Remained Commander Of A Large Section Of The Rashidun Army During The Time Of The Rashid.<br></br> Caliph Umar And Was On The List Of Umar’s Appointed Successors To The Caliphate , Derar Bin Azuar & Sharhabeel Bin Hasnah ,we Drive Back to Amman Hotel for Dinner & Overnight.</p>
            <p><strong>DAY 03: AMMAN HOTEL- ALLENBY BRIDGE TO DEPART TO JERUSALEM–JERICHO – JERUSALEM- Arrival</strong> Allenby Border, Cross Border, Meet & Assist by our Company Representative, proceed to visit Jericho Is a Palestinian City Located Near the Jordan River in The West Bank. It Is Believed to Be One of the Oldest Inhabited Cities in The World, Lunch, Drive to visit Masjid &Maqaam Nabi Mousa, Transfer to Jerusalem for Dinner & Overnight.</p>
            <p><strong>DAY 04: WALKING TOUR IN THE OLD CITY</strong><br></br> Breakfast, Drive to Mount of Olives to visit Maqam Rabeaa Al-Dowy& Maqam Salman Al-Farsi, Panoramic View of Jerusalem, Proceed to the Old City of Jerusalem, Start with the third holiest Mosque for the Muslims & First Qibla,Al-Aqsa Mosque, Visit Dome of the Rock, AL Musalla Al Marawni& Masjid Al Qibli&The Old Aqsa Mosque & Buraq Mosque, Walk into Via Dolorosa Road the way of the cross, visit Church of the Holy Seplchre ,Proceed to Mount Zion to Visit Tomb of David & The Last Supper Room , Lunch,Back to the Hotel for Dinner & Overnight.</p>
            <p><strong>DAY 05: HEBRON – BETHLEHEM - JERUSALEM</strong><br></br> Breakfast, Drive Towards Hebron to Visit Al Haram Al-Ibrahimi where lies the Grave of the three illustrious Prophets & their wives Prophet Abraham & his Son Issac&Jacoub his son, proceed to visit Maqam. Nabi Younes in Halhul,Lunch, Drive to Bethlehem to visit the Birth Place of Jesus the Nativity Church, Visit Masjid Omar, Back to the Hotel for Dinner & Overnight.</p>
            <p><strong>DAY 06: JERUSALEM – ALLENBY BORDER - AMMAN AIRPORT FOR DEPARTURE</strong><br></br> Breakfast, Transfer to the Allenby Border for Departure.</p>
            <span className="theline"></span>
            <u>Note: Package details are tentative and subject to change without any prior notice.</u>
            <div>
                {/* inclusion jordan */}
                <h2 onClick={() => toggleDropdown('inclusionjordan')}>
      {ziyarathcostlyStates.inclusionjordan ? '-' : '+'} Inclusions in Jordan side: 
      </h2>
      <ul className={`subcategory-list ${ziyarathcostlyStates.inclusionjordan ? 'open' : ''}`}>
        <li> Jordan Visa</li>
        <li> Personalised meet & assist upon arrival at the Airport by Talbiya Umrah Representative.</li>
        <li> All transportations with non-smoking, A/C deluxe vehicle with Mineral Water in Bus.</li>
        <li> All transfers mentioned in the itinerary & Entrance fees & Departure tax</li>
        <li>  All airport/train stations pick up/drop off services</li>
        <li>  All Hotel accommodation with Full board meal basis</li>
        <li> Professional Licensed tour guide for entire tour.</li>
        <li>  PCR Test</li>
        <li>  Local staff assist to you, every step always</li>
        <li> 24X7 Customer care support</li>
        
      </ul>
                {/* inclusion jerusalem */}
                <h2 onClick={() => toggleDropdown('inclusionjerusalem')}>
      {ziyarathcostlyStates.inclusionjerusalem ? '-' : '+'} Inclusions in the holy land Jerusalem side: 
      </h2>
      <ul className={`subcategory-list ${ziyarathcostlyStates.inclusionjerusalem ? 'open' : ''}`}>
        <li> Entry Visa</li>
        <li> Depart Tax 55$</li>
        <li> Professional Licensed tour guide for entire tour.</li>
        <li> All Mentioned Entrance Fee</li>
        <li> Escort Professional English Guide</li>
        <li>  1 Bottle of water Per pax /Per day</li>
        <li>  All tours & transfers with Deluxe A/C Bus with Wi-Fi</li>
        <li> 1 Bottle of water Per pax /Per day</li>
        <li>  Full/Board Accommodation (Breakfast – Lunch - Dinner)</li>
        
      </ul>
                {/* exclusion */}
                <h2 onClick={() => toggleDropdown('exclusion')}>
      {ziyarathcostlyStates.exclusion ? '-' : '+'} Exclusion: 
      </h2>
      <ul className={`subcategory-list ${ziyarathcostlyStates.exclusion ? 'open' : ''}`}>
        <li>  Airfare not Included</li>
        <li> GST & TCS not included</li>
        <li> Personal spending & Suggested Optional Activities</li>
        <li> Optional activities not included</li>
        <li>  Room Services ,Tips for Hotels, drivers or guides</li>
        <li> Facilities and services which are not mentation in this package</li>
        <li>  holy land- Jerusalem -Tips 5$ P.P.P.D</li>
        
        
      </ul>
      <h2 onClick={() => toggleDropdown('pointtoremember')}>
      {ziyarathcostlyStates.pointtoremember ? '-' : '+'} Points to remember:
      </h2>
      <ul className={`subcategory-list ${ziyarathcostlyStates.pointtoremember ? 'open' : ''}`}>
        <li> GST & TCS will be applicable.</li>
        <li> Travel agency is not responsible for the loss of luggage, if any.</li>
        <li> Extensions of flight routes will be charged and must be confirmed at the time of booking.</li>
        <li> Original passports must be submitted to our travel agency for visa endorsement before the deadline dates provided by us.</li>
        <li> Hotels and flight schedules are subject to change without prior notice.</li>
      </ul>

      <h2 onClick={() => toggleDropdown('termsandcondtions')}>
      {ziyarathcostlyStates.termsandcondtions ? '-' : '+'} Terms and Conditions: 
      </h2>
      <ul className={`subcategory-list ${ziyarathcostlyStates.termsandcondtions ? 'open' : ''}`}>
        <li> All bookings are subject to license approved by MOMA and the quota allotted by MOMA.</li>
        <li> If any fluctuation in taxes/currency by the Indian or Saudi Government, passengers have to bear the difference.</li>
        
      </ul>

      {/* Add more dropdown sections as needed */}
    </div>

            </div>
           
          </div>
          

          {/* <div className="services" id="serviceid">
   <h2>Our Services</h2>
   <div className="servicescontainer">
   
   <img src={zamzam} alt="logo"></img> 
   <img src={buffet} alt="logo"></img> 
   <img src={bus} alt="logo"></img> 
   <img src={insurance} alt="logo"></img> 
   <img src={laundry} alt="logo"></img> 
   <img src={travelbag} alt="logo"></img> 
  <img src={visa} alt="logo"></img> 
   <img src={airticket1} alt="logo"></img> 
   <img src={ziyarath} alt="logo"></img>
   <img src={hotel} alt="logo"></img> 
   </div>
  
   
   </div>  */}
 <h2>Package Include:</h2>
   <marquee className="servicesMovingContainer">
   <img src={airticket1} alt="logo"></img>
   <img src={visa} alt="logo"></img>  
   <img src={insurance} alt="logo"></img>
   <img src={buffet} alt="logo"></img>  
   <img src={hotel} alt="logo"></img> 
   <img src={ziyarath} alt="logo"></img>
   <img src={bus} alt="logo"></img> 
   <img src={laundry} alt="logo"></img> 
 
   <img src={travelbag} alt="logo"></img> 
  
  
  
   
  
  
   
   
   </marquee>


   


      </main>
    );
  };
  
  export default ZiyarathCostlyPage;