import React,  { useState, useRef  } from 'react';
import '../css/commencsspage.css';
import { Routes, Route, Link ,useNavigate} from 'react-router-dom';
import mainimagecomman from '../images/widekabbaimage.jpg';
import airticket1 from '../images/services/airticket.png';
import buffet from '../images/services/buffet.png';
import bus from '../images/services/bus.png';
import hotel from '../images/services/hotel.png';
import insurance from '../images/services/insurance.png';
import laundry from '../images/services/laundry.png';
import travelbag from '../images/services/travelbag.png';
import visa from '../images/services/visa.png';
import zamzam from '../images/services/zamzam.png';
import ziyarath from '../images/services/ziyarath.png';
import travelimage from '../images/travelimage.jpg';
import aqsa from '../images/aqsa.jpg';
import aqsa2 from '../images/aqsa2.jpg';
import ziyaratpackage from '../images/ziyaratpackage.jpg';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const ZiyarathPage = () => {
  const [ziyarathStates, setZiyarathStates] = useState({
    pointtoremember: false,
    termsandconditions: false,
    schools: false,
    computers: false,
    // Add more dropdown keys as needed
  });

  const toggleDropdown = (dropdown) => {
    setZiyarathStates((prevState) => ({
      ...prevState,
      [dropdown]: !prevState[dropdown],
    }));
  };

  const closeDropdown = (dropdown) => {
    setZiyarathStates((prevState) => ({
      ...prevState,
      [dropdown]: false,
    }));
  };
    return (
      <main className="commanPageContainer" >
          <div className="commanPageImageContainer">
            <img src={aqsa} alt="no image found"></img>
            <div className="commanheader"><h1>Ziyarat</h1>
            <p>Embark on a sacred pilgrimage to the holiest sites with our enriching Ziyarath tours, where faith and history converge.</p>
            </div>
          </div>
         
          <div className="commanPageContentContainer">
          <div className='cardContainer'>
                <div className='cardPackage'>
                    <img src={aqsa2} alt="no image found"></img>
                   
<h1>Masjid Al Aqsa-Bait Al Muqaddas
Holy Land Tour 2024</h1>
<p><strong>Flight Departure:</strong> Bangalore</p>
<p><strong>From</strong>₹ 1,25,000<strong>/Per Person</strong></p>

      <span><FontAwesomeIcon icon={faClock} />  Package
06 Days/ 05 Nights</span>
<div className="buttondiv"><Link to="/ziyarathhighpackpage">
<button>Read More</button>
    </Link></div>

                </div>
                <div className='cardPackage'>
                    <img src={ziyaratpackage} alt="no image found"></img>
                    <h1>Economy Baghdad Ziyarat Package 2024
</h1>
<p><strong>Flight Departure:</strong> Bangalore</p>
<p><strong>From</strong>₹ 95,000<strong>/Per Person</strong></p>

      <span><FontAwesomeIcon icon={faClock} />  Package
10 Days (05 N Baghdad/02 N Karbala & 02 N Najaf)</span>
<div className="buttondiv"><Link to="/ziyaratheconomypackpage">
<button>Read More</button>
    </Link></div>
                </div>
                
                </div>
            
           
          </div>
          

          {/* <div className="services" id="serviceid">
   <h2>Our Services</h2>
   <div className="servicescontainer">
   
   <img src={zamzam} alt="logo"></img> 
   <img src={buffet} alt="logo"></img> 
   <img src={bus} alt="logo"></img> 
   <img src={insurance} alt="logo"></img> 
   <img src={laundry} alt="logo"></img> 
   <img src={travelbag} alt="logo"></img> 
  <img src={visa} alt="logo"></img> 
   <img src={airticket1} alt="logo"></img> 
   <img src={ziyarath} alt="logo"></img>
   <img src={hotel} alt="logo"></img> 
   </div>
  
   
   </div>  */}
 


   


      </main>
    );
  };
  
  export default ZiyarathPage;