import React,  { useState, useRef  } from 'react';
import {Link } from 'react-router-dom';
import '../css/commencsspage.css';
import mainimagecomman from '../images/widekabbaimage.jpg';
import airticket1 from '../images/services/airticket.png';
import buffet from '../images/services/buffet.png';
import bus from '../images/services/bus.png';
import hotel from '../images/services/hotel.png';
import insurance from '../images/services/insurance.png';
import laundry from '../images/services/laundry.png';
import travelbag from '../images/services/travelbag.png';
import visa from '../images/services/visa.png';
import zamzam from '../images/services/zamzam.png';
import ziyarath from '../images/services/ziyarath.png';
import travelimage from '../images/travelimage.jpg';
import kaaba4 from '../images/kaaba4.jpg';
import kaaba3 from '../images/kaaba3.jpg';
import kaaba2 from '../images/kaaba2.jpg';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UmrahPage = () => {
  const [umrahStates, setUmrahStates] = useState({
    pointtoremember: false,
    termsandconditions: false,
    schools: false,
    computers: false,
    // Add more dropdown keys as needed
  });

  const toggleDropdown = (dropdown) => {
    setUmrahStates((prevState) => ({
      ...prevState,
      [dropdown]: !prevState[dropdown],
    }));
  };

  const closeDropdown = (dropdown) => {
    setUmrahStates((prevState) => ({
      ...prevState,
      [dropdown]: false,
    }));
  };
    return (
      <main className="commanPageContainer" >
       
          <div className="commanPageImageContainer">
            <img src={mainimagecomman} alt="no image found"></img>
            <div className="commanheader"><h1>Umrah</h1>
            <p>comman is a significant annual pilgrimage to Mecca, mandatory for Muslims who are physically and financially capable, symbolizing unity and devotion to Allah, following the footsteps of Prophet Abraham's legacy.  </p>
            </div>
          </div>
         
          <div className="commanPageContentContainer">
          <div className='cardContainer'>
                <div className='cardPackage'>
                    <img src={kaaba3} alt="no image found"></img>
                   
                    <h1>Umrah Economy Package 2024</h1>
<p><strong>Flight Departure:</strong> Bangalore</p>
<p><strong>From</strong>₹ 85,000<strong>/Per Person</strong></p>
<p><strong>Duration:</strong> 15 Days</p>
<p><strong>Makkah Hotel within 850 Meters </strong></p>
<p><strong>Madina Hotel within 250 Meters </strong></p>
<p><strong>Food:</strong> Silver Menu</p>

      <span><FontAwesomeIcon icon={faClock} />  Package
15 Days</span>
<div className="buttondiv"><Link to="/umrahpackage1">
<button>Read More</button>
    </Link></div>
                </div>
                <div className='cardPackage'>
                    <img src={kaaba2} alt="no image found"></img>
                    <h1>Umrah Premium Package 2024</h1>
<p><strong>Flight Departure:</strong> Bangalore</p>
<p><strong>From</strong>₹ 99,000<strong>/Per Person</strong></p>
<p><strong>Duration:</strong> 20 Days</p>
<p><strong>Makkah Hotel within 450 Meters </strong></p>
<p><strong>Madina Hotel within 200 Meters </strong></p>
<p><strong>Food:</strong> Platinum Menu</p>
      <span><FontAwesomeIcon icon={faClock} />  Package
20 Days</span>
<div className="buttondiv"><Link to="/umrahpackage2">
<button>Read More</button>
    </Link></div>
                </div>
                <div className='cardPackage'>
                    <img src={kaaba4} alt="no image found"></img>
                    <h1>Umrah Deluxe Package 2024</h1>
<p><strong>Flight Departure:</strong> Bangalore</p>
<p><strong>From</strong>₹ 1,05,000<strong>/Per Person</strong></p>
<p><strong>Duration:</strong> 15 Days</p>
<p><strong>Makkah Hotel within 550 Meters </strong></p>
<p><strong>Madina Hotel within 250 Meters </strong></p>
<p><strong>Food:</strong> Golden Menu</p>

      <span><FontAwesomeIcon icon={faClock} />  Package
15 Days</span>
<div className="buttondiv"><Link to="/umrahpackage3">
<button>Read More</button>
    </Link></div>
                </div>
                
                </div>
           
           
          </div>
          

          {/* <div className="services" id="serviceid">
   <h2>Our Services</h2>
   <div className="servicescontainer">
   
   <img src={zamzam} alt="logo"></img> 
   <img src={buffet} alt="logo"></img> 
   <img src={bus} alt="logo"></img> 
   <img src={insurance} alt="logo"></img> 
   <img src={laundry} alt="logo"></img> 
   <img src={travelbag} alt="logo"></img> 
  <img src={visa} alt="logo"></img> 
   <img src={airticket1} alt="logo"></img> 
   <img src={ziyarath} alt="logo"></img>
   <img src={hotel} alt="logo"></img> 
   </div>
  
   
   </div>  */}
 



   


      </main>
    );
  };
  
  export default UmrahPage;