// WhatsAppStickyIcon.js
import React from 'react';
import whatsapp1 from '../images/whatsapp1.png';
import '../css/whatsappicon.css'
const WhatsAppStickyIcon = () => {
 
  return (
    <div className="whatsapp-sticky-icon" >
         <a href="https://web.whatsapp.com/send?phone=919538322223&text=Hi%0AI%20found%20it%2C%0A%0Aalkausartours%0A%0Ahttps%3A%2F%2Falkausartours.com" target="_blank" className="social-icon">
      <img src={whatsapp1} alt="whatsapp img loading" className='whatsappimg'></img>
      </a>
    </div>
  );
};

export default WhatsAppStickyIcon;
