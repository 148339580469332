import React, { useState } from 'react';
import '../css/headercss.css';
import logo from '../images/Al_Kausar_Gold_Card_D-removebg-preview.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars,faTimes} from '@fortawesome/free-solid-svg-icons';
import {Link } from 'react-router-dom';

const HeaderComponent = () => {
  
  const scrollToSection = (sectionId,event) => {
    event.preventDefault();
    const section = document.getElementById(sectionId);
    
    if (section) {
      const headerHeight = 60; // Replace with the actual height of your fixed header
      const offset = section.offsetTop - headerHeight;

      window.scrollTo({
        top: offset,
        behavior: 'smooth',
      });
    }
  };

  const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
  return (
    <header >
      <link rel="icon" href="../images/Al_Kausar_Gold_Card_D-removebg-preview.png" />
      <div  className={`headdiv${isOpen ? '-active' : ''}`}>

      <div className="logo_container">
            <a href="#"><img src={logo}  alt="logo" /></a>
            <div className='icon' onClick={toggleMenu}>
                {isOpen ? <FontAwesomeIcon icon={faTimes} className='handburgericon' /> : <FontAwesomeIcon icon={faBars} className='handburgericon'/>}
            </div>
        </div>
        
        {/* <FontAwesomeIcon icon={faBars} className='handburgericon'/>  */}
        {/* OLD NAV BAR CODE BELOW */}
      <nav className="navbar">
        <div className="underline"><a href="/">Home</a></div>
          
        <div className="underline"> <a href="/gallerypage"> About Us</a></div>
        <div className="underline"> <a href="/umrahpage" >Umrah</a></div>
        <div className="underline"><a href="/hajjPage" >Hajj</a></div>
        <div className="underline"> <a href="#" >Blogs</a></div>
        <div className="underline"><a href="#"onClick={(e) => scrollToSection('contactusid',e)}>Contact Us</a></div>
      </nav>
    
    {/* <nav className="navbar">
        <div className="underline"><Link to="/">Home</Link></div>
          
        <div className="underline"> <Link to="/gallerypage">About Us</Link></div>
        <div className="underline"> <Link to="/umrahpage">Umrah</Link></div>
        <div className="underline"><Link to="/hajjPage">Hajj</Link></div>
        <div className="underline"> <Link to="/">Blogs</Link></div>
        <div className="underline"><Link to="/">Contact Us</Link></div>
      </nav>  */}

      </div>
      <div className="moving-text-container">
      {/* <div className="moving-text">Your moving text goes here!</div> */}
      <marquee>For more information contact us at +91-95383 22223, +91-99802 56567 , +91-97386 49714, +966-5690 40227, 0816-4059886, or you may email us : 
info@alkausartours.com, sales@alkausartours.com	visa@alkausartours.com	aktoursinternational@gmail.com</marquee>
    </div>
   
    </header>
  );
};

export default HeaderComponent;