import React,  { useState, useRef  } from 'react';
import '../css/commencsspage.css';
import { Routes, Route, Link ,useNavigate} from 'react-router-dom';
import mainimagecomman from '../images/widekabbaimage.jpg';
import airticket1 from '../images/services/airticket.png';
import buffet from '../images/services/buffet.png';
import bus from '../images/services/bus.png';
import hotel from '../images/services/hotel.png';
import insurance from '../images/services/insurance.png';
import laundry from '../images/services/laundry.png';
import travelbag from '../images/services/travelbag.png';
import visa from '../images/services/visa.png';
import zamzam from '../images/services/zamzam.png';
import ziyarath from '../images/services/ziyarath.png';
import travelimage from '../images/travelimage.jpg';
import aqsa from '../images/aqsa.jpg';
import aqsa2 from '../images/aqsa2.jpg';
import ziyaratpackage from '../images/ziyaratpackage.jpg';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const ZiyarathEconomyPage = () => {
  const [ziyaratheconomyStates, setZiyarathEconomyStates] = useState({
    pointtoremember: false,
    termsandconditions: false,
    inclusion: false,
    exclusion:false,
    // Add more dropdown keys as needed
  });

  const toggleDropdown = (dropdown) => {
    setZiyarathEconomyStates((prevState) => ({
      ...prevState,
      [dropdown]: !prevState[dropdown],
    }));
  };

  const closeDropdown = (dropdown) => {
    setZiyarathEconomyStates((prevState) => ({
      ...prevState,
      [dropdown]: false,
    }));
  };
    return (
      <main className="commanPageContainer" >
          <div className="commanPageImageContainer">
            <img src={aqsa} alt="no image found"></img>
            <div className="commanheader"><h1>Ziyarat</h1>
            <p>Embark on a sacred pilgrimage to the holiest sites with our enriching Ziyarath tours, where faith and history converge.</p>
            </div>
          </div>
         
          <div className="commanPageContentContainer">
          
                
               
            <div className='commanPageMainContainer'>
                
            <h1>Economy Baghdad Ziyarat Package 2024</h1>
            
            <h3>Ziyarath PACKAGE FROM BANGALORE 2024</h3>
            <p>Baghdad is one of the most sacred places for muslims because according to Islamic history it is the resting place of those saints who sacrificed themselves to protect Islam and its followers in addition to that these saints were admired for their life-changing lessons and sayiungs. They lived their lives precisely in accordance to the teachings of holly Quran.</p>
            <p>This package has been designed for those Muslims who have not forgotten the contribution of holy saints in furthering the teachings of Almighty and our beloved Prophet (PBUH), and Muslims who calls upon the saints for barakat and blessings. This package has been assembled in such a way that it will not cost an arm and leg to the pilgrim to rejuvenate his/her faith. All the additional luxury has been removed yet the facilities and amenities which are kept in the package will make your pilgrimage congenial.</p>
            
            
            <h3>Recommendations:</h3>
            {/* <div className="recommended">
              
              <div className="rpackage"></div>
              <div className="rpackage"></div>
              <div className="rpackage"></div>
            </div> */}
            <a href="/ziyarathhighpackpage">Economy Baghdad Ziyarat Package 2024</a>
            <a href="/umrahpage">Umrah</a>
            <a href="/hajjPage">Hajj</a>
            <p><strong>Departure Cities:</strong>Bangalore</p>
            <span className="theline"></span>
           
            
            <h3>Departure:</h3>
            <p><strong>DATE (BOM) :</strong>Every Month Last</p>

            {/* <span className="theline"></span> */}
            <div className="purchaseBanner"><span className="purchaseSpan"><strong>Pre-Book</strong></span></div>
            <span className="theline"></span>
            <u>Note: Package details are tentative and subject to change without any prior notice.</u>
            <div>
             
                {/* inclusion*/}
                <h2 onClick={() => toggleDropdown('inclusion')}>
      {ziyaratheconomyStates.inclusion? '-' : '+'} Inclusions in the holy land Jerusalem side: 
      </h2>
      <ul className={`subcategory-list ${ziyaratheconomyStates.inclusion ? 'open' : ''}`}>
        <li> Economy Class Flight</li>
        <li> 10 Days (05 N Baghdad+ 02 N Karbala & 02 N Najaf)</li>
        <li> Visa for Iraq</li>
        <li> Hotel Accommodation (Economy)</li>
        <li> Full Board -Buffet system prepared by Chefs in Hygienic Kitchens</li>
        <li>  Ziyarat tours with experienced tour guide</li>
        <li> Deluxe buses for full Transportation</li>
        <li> local staff at your service</li>
        <li>  24X7 customer support</li>
        
      </ul>
                {/* exclusion */}
                <h2 onClick={() => toggleDropdown('exclusion')}>
      {ziyaratheconomyStates.exclusion ? '-' : '+'} Exclusion: 
      </h2>
      <ul className={`subcategory-list ${ziyaratheconomyStates.exclusion ? 'open' : ''}`}>
        <li>  GST 5% & TCS 5%</li>
        <li> Facilities, services and amenities which are not indicated in this package</li>
        <li> charges have to be paid in case of excess luggage</li>
        <li> Neither tour operator nor the tour guide will be held accountable for loss of luggage</li>
        <li> No room service applicable in this package</li>
        <li> No refund is applicable for unutilized/unused services</li>
        <li> No refund is applicable if the stay is curtailed</li>
        
        
      </ul>
      <h2 onClick={() => toggleDropdown('pointtoremember')}>
      {ziyaratheconomyStates.pointtoremember ? '-' : '+'} Points to remember:
      </h2>
      <ul className={`subcategory-list ${ziyaratheconomyStates.pointtoremember ? 'open' : ''}`}>
        <li> GST & TCS will be applicable.</li>
        <li> Travel agency is not responsible for the loss of luggage, if any.</li>
        <li> Extensions of flight routes will be charged and must be confirmed at the time of booking.</li>
        <li> Original passports must be submitted to our travel agency for visa endorsement before the deadline dates provided by us.</li>
        <li> Hotels and flight schedules are subject to change without prior notice.</li>
      </ul>

      <h2 onClick={() => toggleDropdown('termsandcondtions')}>
      {ziyaratheconomyStates.termsandcondtions ? '-' : '+'} Terms and Conditions: 
      </h2>
      <ul className={`subcategory-list ${ziyaratheconomyStates.termsandcondtions ? 'open' : ''}`}>
        <li> All bookings are subject to license approved by MOMA and the quota allotted by MOMA.</li>
        <li> If any fluctuation in taxes/currency by the Indian or Saudi Government, passengers have to bear the difference.</li>
        
      </ul>

      {/* Add more dropdown sections as needed */}
    </div>

            </div>
           
          </div>
          

          {/* <div className="services" id="serviceid">
   <h2>Our Services</h2>
   <div className="servicescontainer">
   
   <img src={zamzam} alt="logo"></img> 
   <img src={buffet} alt="logo"></img> 
   <img src={bus} alt="logo"></img> 
   <img src={insurance} alt="logo"></img> 
   <img src={laundry} alt="logo"></img> 
   <img src={travelbag} alt="logo"></img> 
  <img src={visa} alt="logo"></img> 
   <img src={airticket1} alt="logo"></img> 
   <img src={ziyarath} alt="logo"></img>
   <img src={hotel} alt="logo"></img> 
   </div>
  
   
   </div>  */}
 <h2>Package Include:</h2>
   <marquee className="servicesMovingContainer">
   <img src={airticket1} alt="logo"></img>
   <img src={visa} alt="logo"></img>  
   <img src={insurance} alt="logo"></img>
   <img src={buffet} alt="logo"></img>  
   <img src={hotel} alt="logo"></img> 
   <img src={ziyarath} alt="logo"></img>
   <img src={bus} alt="logo"></img> 
   <img src={laundry} alt="logo"></img> 
 
   <img src={travelbag} alt="logo"></img> 
  
  
  
   
  
  
   
   
   </marquee>


   


      </main>
    );
  };
  
  export default ZiyarathEconomyPage;