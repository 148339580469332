import React, { useState } from 'react';
import '../css/gallerypagecss.css';
import galleryimage from '../images/gallerypageimage.png';
import { Routes, Route, Link } from 'react-router-dom';
import bookingicon from '../images/bookingicon.png';
import flighticon from '../images/flighticon.png';
import checklisticon from '../images/checklisticon.png';
import paymenticon from '../images/paymenticon.png';
import zaid from '../images/zaid.jpg';
const GalleryPageComponent = () => {

    return(
<div className="galleryPageContainer">
    <div className="galleryPageMainContent">
    <img src={galleryimage} alt="no image found"></img> 
        <div className="galleryPageTitleContainer">
        <h1>Next Stop Explore The World.</h1>
        <p>Discover breathtaking destinations through our captivating travel gallery.</p>
        <Link to="/">
<button>Home</button>
    </Link>
        </div>
    </div>


<div className="galleryPageBanner">
<div className="galleryPageBannerContent">
<img src={bookingicon} alt="no image found"></img> 
<p>BOOKING</p>
</div>
<div className="galleryPageBannerContent">
<img src={flighticon} alt="no image found"></img> 
<p>FLIGHT</p>
</div>
<div className="galleryPageBannerContent">
<img src={checklisticon} alt="no image found"></img> 
<p>CHECKLIST</p>
</div>
<div className="galleryPageBannerContent">
<img src={paymenticon} alt="no image found"></img> 
<p>PAYMENT</p>
</div>
</div>

<div className="galleryPageDetails">
    <h1>Know About Al Kausar</h1>
    <p>
    After Successfully Serving the Hujjaj Through Bombay Hajj Committee, AL Haj Abdul Razak Sab A Renowned Hajj & Umrah Service Provider In Karnataka & His Son Mohammed Haneef Established AL KAUSAR TOURS INTERNATIONAL in the Year 2007 at Tumkur.
<br></br>
<br></br>
AL KAUSAR TOURS INTERNATIONAL is a leading Service provider in the Haj & Umrah Travel Industry We are one of the leading Company in this sector to provide a classic Service to its Customers Based on there Requirements. Alhamdulillah we are serving the Guest's of Allah Since the Generations. Alhamdulillah We are the Registered Tour operators Since our Establishment and we are very well known for our Quality Management And Service. Also we are an ISO Certified Company.
<br></br>
<br></br>
The Aim And Objective For Bringing It Was To Give Good Service For The People Who Travel For Pilgrimage To The House Of Allah (For Haj/Umrah/Ziyarath) To Kingdom Of Saudi Arabia. We Have Extended Our Service And We Are Delighted To Serve Our Customers In Jerusalem (Baitul Muqadas Masjid Al Aqsa), Iraq (Baghdad, Karbala, NajafKufa, Hilla), Amman (Jordan), Turkey, Malaysia, Indonesia, Dubai, Muscat, Salalah, Egypt, Etc.
<br></br>
<br></br>
Our founders have established our firm with a great Vison, Mission & Values and Alhamdulillah by the grace of almighty since the Establishment we are successful with it.
<br></br>
<br></br>
<strong>Our Vision:</strong> To Provide excellent customer service that will enable us to remain Competitive in the market.
<br></br>
<br></br>
<strong>Our Mission:</strong> To provide the better experience to the clients through an excellent service with knowledge that are socially and environmentally friendly to meet the wishes of our customers always exceeding their expectations.
<br></br>
<br></br>
<strong>Our Values:</strong> To emerge as the leading company offering good services at a Competative prices by providing Classic Service, with Transparency, Ethics, Politeness, Sustainability & Ensuring high standards of customer satisfaction and class operating efficiency thereby becoming a model in every sector.
    </p>
    <span className="theline"></span>
</div>

<div className='galleryPageGalleryContainer'>

    <h1>Our Gallery</h1>


    
    <div className='imagegallery'><img src={zaid} alt="Background Image" class="zaid-img"></img> </div>

    
</div>
<div class="video-container">
    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/3QpcTfVM-v4" frameborder="0" allowfullscreen></iframe>
  </div>




</div>


    );
};

export default GalleryPageComponent;