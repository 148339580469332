import React,  { useState, useRef  } from 'react';
import {Link } from 'react-router-dom';
import '../css/commencsspage.css';
import mainimagecomman from '../images/widekabbaimage.jpg';
import airticket1 from '../images/services/airticket.png';
import buffet from '../images/services/buffet.png';
import bus from '../images/services/bus.png';
import hotel from '../images/services/hotel.png';
import insurance from '../images/services/insurance.png';
import laundry from '../images/services/laundry.png';
import travelbag from '../images/services/travelbag.png';
import visa from '../images/services/visa.png';
import zamzam from '../images/services/zamzam.png';
import ziyarath from '../images/services/ziyarath.png';
import kaaba3 from '../images/kaaba3.jpg';
import kaaba2 from '../images/kaaba2.jpg';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const HajjPage = () => {
  const [dropdownStates, setDropdownStates] = useState({
    pointtoremember: false,
    termsandconditions: false,
    schools: false,
    computers: false,
    // Add more dropdown keys as needed
  });

  const toggleDropdown = (dropdown) => {
    setDropdownStates((prevState) => ({
      ...prevState,
      [dropdown]: !prevState[dropdown],
    }));
  };

  const closeDropdown = (dropdown) => {
    setDropdownStates((prevState) => ({
      ...prevState,
      [dropdown]: false,
    }));
  };
    return (
      <main className="commanPageContainer" >
          <div className="commanPageImageContainer">
            <img src={mainimagecomman} alt="no image found"></img>
            <div className="commanheader"><h1>Hajj</h1>
            <p>Hajj is a significant annual pilgrimage to Mecca, mandatory for Muslims who are physically and financially capable, symbolizing unity and devotion to Allah, following the footsteps of Prophet Abraham's legacy.  </p>
            </div>
          </div>
         
          <div className="commanPageContentContainer">
          <div className='cardContainer'>
                <div className='cardPackage'>
                    <img src={kaaba3} alt="no image found"></img>
                   
<h1>Hajj 37 Days Shifting Package 2024</h1>
<p><strong>Flight Departure:</strong> Bangalore</p>
<p><strong>From</strong>₹ 8,00,000<strong>/Per Person</strong></p>

      <span><FontAwesomeIcon icon={faClock} />  Package
37 Days</span>
<div className="buttondiv"><Link to="/hajjlongpackage">
<button>Read More</button>
    </Link></div>
                </div>
                <div className='cardPackage'>
                    <img src={kaaba2} alt="no image found"></img>
                    <h1>Hajj Short Package 2024</h1>
<p><strong>Flight Departure:</strong> Bangalore</p>
<p><strong>From</strong>₹ 8,25,000<strong>/Per Person</strong></p>

      <span><FontAwesomeIcon icon={faClock} />  Package
20 Days</span>
<div className="buttondiv"><Link to="/hajjshortpackage">
<button>Read More</button>
    </Link></div>
                </div>
                
                </div>
           
           
          </div>
          

          {/* <div className="services" id="serviceid">
   <h2>Our Services</h2>
   <div className="servicescontainer">
   
   <img src={zamzam} alt="logo"></img> 
   <img src={buffet} alt="logo"></img> 
   <img src={bus} alt="logo"></img> 
   <img src={insurance} alt="logo"></img> 
   <img src={laundry} alt="logo"></img> 
   <img src={travelbag} alt="logo"></img> 
  <img src={visa} alt="logo"></img> 
   <img src={airticket1} alt="logo"></img> 
   <img src={ziyarath} alt="logo"></img>
   <img src={hotel} alt="logo"></img> 
   </div>
  
   
   </div>  */}
 {/* <h2>Package Include:</h2>
   <marquee className="servicesMovingContainer">
   <img src={airticket1} alt="logo"></img>
   <img src={visa} alt="logo"></img>  
   <img src={insurance} alt="logo"></img>
   <img src={buffet} alt="logo"></img>  
   <img src={hotel} alt="logo"></img> 
   <img src={ziyarath} alt="logo"></img>
   <img src={bus} alt="logo"></img> 
   <img src={laundry} alt="logo"></img> 
   <img src={zamzam} alt="logo"></img> 
   <img src={travelbag} alt="logo"></img> 
  
  
  
   
  
  
   
   
   </marquee> */}


   


      </main>
    );
  };
  
  export default HajjPage;