import React,  { useState, useRef  } from 'react';
import {Link } from 'react-router-dom';
import '../css/commencsspage.css';
import mainimagecomman from '../images/widekabbaimage.jpg';
import airticket1 from '../images/services/airticket.png';
import buffet from '../images/services/buffet.png';
import bus from '../images/services/bus.png';
import hotel from '../images/services/hotel.png';
import insurance from '../images/services/insurance.png';
import laundry from '../images/services/laundry.png';
import travelbag from '../images/services/travelbag.png';
import visa from '../images/services/visa.png';
import zamzam from '../images/services/zamzam.png';
import ziyarath from '../images/services/ziyarath.png';
import travelimage from '../images/travelimage.jpg';
import kaaba4 from '../images/kaaba4.jpg';
import kaaba3 from '../images/kaaba3.jpg';
import kaaba2 from '../images/kaaba2.jpg';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dubai from '../images/dubai.jpg';
import turkey from '../images/turkey.jpg';
import thailand from '../images/thailand.jpg';
import bali from '../images/bali.jpg';
import maldives from '../images/maldives.jpg';
import nepal from '../images/nepal.jpg';

const OtherTravelPackage = () => {
  const [umrahpackage3States, setUmrahpacakge3States] = useState({
    pointtoremember: false,
    termsandconditions: false,
    schools: false,
    computers: false,
    // Add more dropdown keys as needed
  });

  const toggleDropdown = (dropdown) => {
    setUmrahpacakge3States((prevState) => ({
      ...prevState,
      [dropdown]: !prevState[dropdown],
    }));
  };

  const closeDropdown = (dropdown) => {
    setUmrahpacakge3States((prevState) => ({
      ...prevState,
      [dropdown]: false,
    }));
  };
    return (
      <main className="commanPageContainer" >
       
          <div className="commanPageImageContainer">
            <img src={travelimage} alt="no image found"></img>
            <div className="commanheader"><h1>Other Package's</h1>
            <p>Explore the world, create memories, and uncover new horizons with us.</p>
            </div>
            
          </div>
          <div className="contactusBanner"><span className="contactusSpan"><strong>Contact Us For More Details</strong></span></div>
        <div className='otherPackages'>
<div className='otherPackagesImageConatiner'>
<img src={dubai} alt="no image found"></img>
        <h1>Dubai</h1>
</div>
<div className='otherPackagesImageConatiner'>
<img src={maldives} alt="no image found"></img>
        <h1>Maldives</h1>
</div>
<div className='otherPackagesImageConatiner'>
<img src={bali} alt="no image found"></img>
        <h1>Bali</h1>
</div>
<div className='otherPackagesImageConatiner'>
<img src={turkey} alt="no image found"></img>
        <h1>Turkey</h1>
</div>
<div className='otherPackagesImageConatiner'>
<img src={thailand} alt="no image found"></img>
        <h1>Thailand</h1>
</div>
<div className='otherPackagesImageConatiner'>
<img src={nepal} alt="no image found"></img>
        <h1>Nepal</h1>
</div>
        </div>


   


      </main>
    );
  };
  
  export default OtherTravelPackage;