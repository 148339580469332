import React, { useState, useEffect } from 'react';
import { Routes, Route, Link ,useNavigate} from 'react-router-dom';
import '../css/maincontent.css';
import mlogo from '../images/mlogo.svg';
import kaaba2 from '../images/kaaba2.jpg';
import mainimage from '../images/alkausartours-3.png';
import airticket from '../images/air ticketing.webp';
import email from '../images/email.png';
import map from '../images/map.png';
import phone from '../images/phone.png';
import otherpackage from '../images/otherpackage.jpg';
import ziyaratpackage from '../images/ziyaratpackage.jpg';
import umrahpackage from '../images/umrahpackage.jpg';
import kaaba from '../images/kaaba.png';
import emirates from '../images/logos/emirates.png';
import etihad from '../images/logos/etihad.png';
import flydubai from '../images/logos/flydubai.png';
import gulfair from '../images/logos/gulfair.png';
import indigo from '../images/logos/indigo.png';
import omanair from '../images/logos/omanair.png';
import qatarairways from '../images/logos/qatarairways.png';
import saudia from '../images/logos/Saudia.png';

import { Helmet } from 'react-helmet';
import whatsapp1 from '../images/whatsapp1.png';
// Import necessary icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft,faBars} from '@fortawesome/free-solid-svg-icons';
import man from '../images/man.png';
import woman from '../images/woman.png';
import profile from '../images/profile.png';
import lamp from '../images/lamp.png';
import rating from '../images/rating.png';
import comprehension from '../images/comprehension.png'; 
import care from '../images/care.png';
import zaid from '../images/zaid.jpg';



const MainContent = () => {
  const scrollToSection = (sectionId,event) => {
    event.preventDefault();
    const section = document.getElementById(sectionId);
    
    if (section) {
      const headerHeight = 60; // Replace with the actual height of your fixed header
      const offset = section.offsetTop - headerHeight;

      window.scrollTo({
        top: offset,
        behavior: 'smooth',
      });
    }
  };
  const [items, setItems] = useState([]);
  const [active, setActive] = useState(3);



  const loadShow = () => {
    if (active < 0 || active >= items.length) {
      return;
    }
    let stt = 0;
    items[active].style.transform = `none`;
    items[active].style.zIndex = 1;
    items[active].style.filter = 'none';
    items[active].style.opacity = 1;

    for (let i = active + 1; i < items.length; i++) {
      stt++;
      items[i].style.transform = `translateX(${120 * stt}px) scale(${1 - 0.2 * stt}) perspective(16px) rotateY(-1deg)`;
      items[i].style.zIndex = -stt;
      items[i].style.filter = 'blur(5px)';
      items[i].style.opacity = stt > 2 ? 0 : 0.6;
    }

    stt = 0;
    for (let i = active - 1; i >= 0; i--) {
      stt++;
      items[i].style.transform = `translateX(${-120 * stt}px) scale(${1 - 0.2 * stt}) perspective(16px) rotateY(1deg)`;
      items[i].style.zIndex = -stt;
      items[i].style.filter = 'blur(5px)';
      items[i].style.opacity = stt > 2 ? 0 : 0.6;
    }
  };

  useEffect(() => {
    loadShow();
  }, [items, active]);

  const handleNext = () => {
    setActive((prevActive) => (prevActive + 1 < items.length ? prevActive + 1 : prevActive));
  };

  const handlePrev = () => {
    setActive((prevActive) => (prevActive - 1 >= 0 ? prevActive - 1 : prevActive));
  };

  const [text, setText] = useState('');
  const originalText = "  AL KAUSAR TOURS INTERNATIONAL was established by ALHAJ ABDUL RAZAK SAB a renowned Haj & Umrah service provider in Karnataka & His Son AL HAJ MOHAMMED HANEEF in the year 2007 at Tumkur, The aim and objective for bringing it was to give good service for the people who travel for pilgrimage to the house of Allah ( For HAJ / UMRAH / ZIYARATH ) to Kingdom of Saudi Arabia. We have extended our service and we are delighted to serve our customers in Jerusalem ( Baitul Muqadas Masjid Al Aqsa), Iraq ( Baghdad, Karbala, Najaf, Kufa, Hilla ), Amman (Jordan), Turkey, Malaysia, Indonesia, Dubai, Muscat, Salalah , Egypt, Etc.\nWe “AL KAUSAR TOURS INTERNATIONAL” Organize UMRAH Every Month, UMRAH IN RAMZAN, HAJ & ZIYARATH Pilgrimage Package.\nWe “AL KAUSAR TOURS INTERNATIONAL“ is a registered under Government of Karnataka and Government of India (Ministry of Tourism)   AL KAUSAR TOURS INTERNATIONAL was established by ALHAJ ABDUL RAZAK SAB a renowned Haj & Umrah service provider in Karnataka & His Son AL HAJ MOHAMMED HANEEF in the year 2007 at Tumkur, The aim and objective for bringing it was to give good service for the people who travel for pilgrimage to the house of Allah ( For HAJ / UMRAH / ZIYARATH ) to Kingdom of Saudi Arabia. We have extended our service and we are delighted to serve our customers in Jerusalem ( Baitul Muqadas Masjid Al Aqsa), Iraq ( Baghdad, Karbala, Najaf, Kufa, Hilla ), Amman (Jordan), Turkey, Malaysia, Indonesia, Dubai, Muscat, Salalah , Egypt, Etc.\nWe “AL KAUSAR TOURS INTERNATIONAL” Organize UMRAH Every Month, UMRAH IN RAMZAN, HAJ & ZIYARATH Pilgrimage Package.\nWe “AL KAUSAR TOURS INTERNATIONAL“ is a registered under Government of Karnataka and Government of India (Ministry of Tourism)";

  useEffect(() => {
    const itemsArray = document.querySelectorAll('.slider .item');
    setItems(itemsArray);
    let index = 0;
    const intervalId = setInterval(() => {
      setText((prevText) => prevText + originalText[index]);
      index++;

      if (index === originalText.length) {
        clearInterval(intervalId);
        setText(originalText);
      }
    }, 1); // Adjust the interval as needed

    return () => clearInterval(intervalId);
  }, []);

  const navigate = useNavigate();

  const handleClick = () => {
    // Redirect to a specific route
    navigate('/gallerypage');
  };

  return (
    
    <main  className="mainClass">
     
    <div className="mainimage_container" id="homeid">
      <div className="maintext"><h1>Hey, Welcome to Al Kausar!</h1>
      <p>Explore the world with us and create unforgettable memories.</p>
      <button onClick={(e) => scrollToSection('contactusid',e)}>Contact Us</button>
      </div>
      
           <div className="mainimg"><img src={kaaba}  alt="mainimage" /></div> 
           
            {/* <h2>Welcome</h2> */}
        </div>
    <div className="aboutus" id="aboutusid">
      
    {/* <img src={ramadanbackground} alt="Background Image" class="background-img"></img> */}
    <div className="aboutusimage"><img src={lamp} alt="logo" ></img> </div> 
    
<div className='aboutuscontainer'>
 


<h1>About Us</h1>
  <p>
  AL KAUSAR TOURS INTERNATIONAL was established by ALHAJ ABDUL RAZAK SAB a renowned Haj & Umrah service provider in Karnataka & His Son AL HAJ MOHAMMED HANEEF in the year 2007 at Tumkur, The aim and objective for bringing it was to give good service for the people who travel for pilgrimage to the house of Allah ( For HAJ / UMRAH / ZIYARATH ) to Kingdom of Saudi Arabia. 
</p>

<div className="last-text">
  <div className="text1">
    <h3>
      17+
    </h3>

    <h5>
      Years of Experience
    </h5>
  </div>

  <div className="text1">
    <h3>
      1500+
    </h3>

    <h5>
      No of Hajis We served
    </h5>
  </div>
</div>
<button onClick={handleClick}>Gallery</button>

</div>

    </div>
{/* why us content div below */}
<div className="whyuscontainer">
<h1>Why Choose Us?</h1>
<h3> We Partner with brave clients to build brand experiences people love.</h3>
<div className="whyuscardcontainer">
<div className="whyuscard">
<img src={rating} atr="not available"></img>
<h1> EXPERTISE & EXPERIENCE</h1>
<p>Decades of expertise in organizing Hajj, Umrah, and Ziyarat, ensuring a seamless and enriching journey</p>
</div>

<div className="whyuscard">
<img src={comprehension} atr="not available"></img>
<h1>TAILORED SERVICES</h1>
<p>Customizable packages, visa assistance, accommodation, and 24/7 customer support for a hassle-free travel experience</p>
</div>

<div className="whyuscard">
<img src={care} atr="not available"></img>
<h1>CUSTOMER SUPPORT</h1>

<p>Responsive and knowledgeable team dedicated to providing on-ground assistance and ensuring client satisfaction</p>
</div>
</div>

</div>

<div className="packagecontainermain">
  <h1>Discover Your Next Adventure: Unleash the World's Wonders with Our Exclusive Travel Packages!</h1>
  <div className="packagecontainer">
<h1>Our<br></br>Packages</h1>
{/* <h3> Discover your next adventure with our meticulously crafted travel packages, tailored to fulfill your wanderlust dreams.</h3> */}
<div className="packagecardcontainer">
<div className="packagecard">
  <div className="packagecardimagecontainer">
  <div className="overlay"></div>
    <img src={kaaba2} atr="no image"></img>
    
  </div>
  <div className="ptag">
  <h3>Hajj</h3>
  </div>
  
<Link to="/hajjpage" className="link-with-arrow">
      View More
      <span className="arrow-icon" >&#10148;</span>
    </Link>
</div>

<div className="packagecard">
<div className="packagecardimagecontainer">
  <div className="overlay"></div>
    <img src={umrahpackage} atr="no image"></img>
    
  </div>
  <div className="ptag">
  <h3>Umrah</h3>
  </div>
  
<Link to="/umrahpage" className="link-with-arrow">
      View More
      <span className="arrow-icon">&#10148;</span>
    </Link>


</div>

<div className="packagecard">
<div className="packagecardimagecontainer">
  <div className="overlay"></div>
    <img src={ziyaratpackage} atr="no image"></img>
   
  </div>
  <div className="ptag">
  <h3>Ziyarat</h3>
  </div>
  
<Link to="/ziyarathpage" className="link-with-arrow">
      View More
      <span className="arrow-icon">&#10148;</span>
    </Link>

</div>
<div className="packagecard">
<div className="packagecardimagecontainer">
  <div className="overlay"></div>
    <img src={otherpackage} atr="no image"></img>
    
  </div>
  <div className="ptag">
  <h3>Other Package's</h3>
  </div>
  
<Link to="/othertravelpack" className="link-with-arrow">
      View More
      <span className="arrow-icon">&#10148;</span>
    </Link>

</div>
</div>
</div>
</div>

    {/* <div className="packages" id="packageid">
<h2>Packages</h2>
<div class="slider">
            <div class="item">
            <img src={alkausarpk} alt="logo"></img> <div className="bottomdiv">Package 1</div>
            </div>
            <div class="item">
            <img src={alkausarpk} alt="logo"></img> <div className="bottomdiv">Package 2</div>
            </div>
            <div class="item">
            <img src={alkausarpk} alt="logo"></img> <div className="bottomdiv">Package 3</div>
            </div>
            <div class="item">
            <img src={alkausarpk} alt="logo"></img> <div className="bottomdiv">Package 4</div>
            </div>
            <div class="item">
            <img src={alkausarpk} alt="logo"></img> <div className="bottomdiv">Package 5</div>
            </div>
            <div class="item">
            <img src={alkausarpk} alt="logo"></img> <div className="bottomdiv">Package 6</div>
            </div>
            <div class="item">
            <img src={alkausarpk} alt="logo"></img> <div className="bottomdiv">Package 7</div>
            </div>
            <button id="next" onClick={handlePrev}>{'>'}</button>
      <button id="prev" onClick={handleNext}>{'<'}</button>
          
        </div>

    </div>
      
   <div className="activity" id="activityid">
   <h2>Activities</h2>
   <div className="activitycontainer">
   <div className="p1"><img src={airticket} alt="logo"></img> <p>AIR TICKETING</p></div>
   <div className="p1"><img src={hajj} alt="logo"></img> <p>Hajj</p></div>
   <div className="p1"><img src={passportassist} alt="logo"></img> <p>PASSPORT ASSISTANCE</p></div>
   <div className="p1"><img src={umrah} alt="logo"></img> <p>UMRAH</p></div>
   <div className="p1"><img src={visassist} alt="logo"></img> <p>VISA ASSIST</p></div>
  
   </div>
   </div> */}

   {/* <div className="services" id="serviceid">
   <h2>Our Services</h2>
   <div className="servicescontainer">
   
   <div className="p1"><img src={zamzam} alt="logo"></img> </div>
   <div className="p1"><img src={buffet} alt="logo"></img> </div>
   <div className="p1"><img src={bus} alt="logo"></img> </div>
   <div className="p1"><img src={insurance} alt="logo"></img> </div>
   <div className="p1"><img src={laundry} alt="logo"></img> </div>
   <div className="p1"><img src={travelbag} alt="logo"></img> </div>
   <div className="p1"><img src={visa} alt="logo"></img> </div>
   <div className="p1"><img src={airticket1} alt="logo"></img> </div>
   <div className="p1"><img src={ziyarath} alt="logo"></img> </div>
   <div className="p1"><img src={hotel} alt="logo"></img> </div>
   </div>
  
   
   </div> */}


<div className='gallerycontainer'>
  <div className='galleryText'>
  <h1>Explore our <strong>breathtaking</strong> collection of images showcasing Umrah and Hajj <strong>journeys.</strong></h1>
<h3>Devotion and pilgrimage in every frame – Umrah & Hajj Gallery.</h3>
<Link to="/gallerypage">
<button>Gallery</button>
    </Link>
  </div>


  <div className='galleryimage'><img src={zaid} alt="Background Image" class="zaid-img"></img> </div>
  




</div>


<div className='getintouchcontainer'>

<h1>Get In Touch</h1>
<h3> Stay connected, reach out, and let's make your travel dreams a reality!</h3>
<div className='getintouchcards'>

<div className='getintouchcard'>
<div className='getintouchcardimage'><img src={map} alt="no image"></img></div>

<h3>Address</h3>

<p>Shop No 04 , <br></br>Madeena Complex,<br></br> melekote Main Road <br></br>Sadashivanagar <br></br>Tumkur-572101</p>
</div>

<div className='getintouchcard'>
<div className='getintouchcardimage'><img src={email} alt="no image"></img></div>

<h3>Email</h3>
<p>alkausartoursinternational@gmail.com</p>
</div>

<div className='getintouchcard'>
  <div className='getintouchcardimage'><img src={phone} alt="no image"></img></div>

<h3>Phone</h3>
<p>95383 22223 <br></br>
  97386 49714</p>
</div>
</div>

</div>

    <div className="testimonialscontainer">
   <h1>Testimonials</h1>
   <h3> Discover what our clients say and join the journey of shared experiences and cherished memories.</h3>

    <div className='cards'>
      <div className='card'>
      <img src={profile} alt="logo"></img>
      <p>Good Travel Experts mashaallah. I recommend everyone to travel from Al Kausar tours Zaid Bhai.</p>
         <h4>Sameer Sultan</h4>
         <h3>Tumkur</h3>
         <FontAwesomeIcon icon={faQuoteLeft} /> 
      </div>

      <div className='card'>
      <img src={man} alt="logo"></img>
      <p>Great experience for travel with Al Kausar. Good management classic service hygienic food and accommodation.</p>
         <h4>mohammed thousif</h4>
         <h3>Tumkur</h3>
         <FontAwesomeIcon icon={faQuoteLeft} /> 
      </div>

      <div className='card'>
      <img src={woman} alt="logo"></img>
      <p>Good service good package good management.</p>
         <h4>Md Tamanna</h4>
         <h3>Bangalore</h3>
         <FontAwesomeIcon icon={faQuoteLeft} /> 
      </div>
    </div>
   </div> 

   <div className="logos">
    <h1>Our Travel Partner:</h1>
      <div className="logos-slide">
      <img src={emirates} alt="logo"></img>
      <img src={etihad} alt="logo"></img>
      <img src={flydubai} alt="logo"></img>
      <img src={gulfair} alt="logo"></img>
      <img src={indigo} alt="logo"></img>
      <img src={omanair} alt="logo"></img>
      <img src={qatarairways} alt="logo"></img>
      <img src={saudia} alt="logo"></img>
      </div>
      <div className="logos-slide">
      <img src={emirates} alt="logo"></img>
      <img src={etihad} alt="logo"></img>
      <img src={flydubai} alt="logo"></img>
      <img src={gulfair} alt="logo"></img>
      <img src={indigo} alt="logo"></img>
      <img src={omanair} alt="logo"></img>
      <img src={qatarairways} alt="logo"></img>
      <img src={saudia} alt="logo"></img>
      </div>
      
    </div>
    

   
      </main>
  );
};

export default MainContent;