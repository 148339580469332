import React from 'react';
import '../css/footercontent.css';
import ethihad from '../images/etihad.png';
import whatsapp from '../images/whatsapp.png';
import instagram from '../images/instagram.png';
import twitter from '../images/twitter.png';
import facebook from '../images/facebook.png';
import logo from '../images/Al_Kausar_Gold_Card_D-removebg-preview.png';
// Import necessary icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobile, faMapMarker, faEnvelope} from '@fortawesome/free-solid-svg-icons';



const FooterContent = () => {

  const scrollToSection = (sectionId,event) => {
    event.preventDefault();
    const section = document.getElementById(sectionId);
    
    if (section) {
      const headerHeight = 60; // Replace with the actual height of your fixed header
      const offset = section.offsetTop - headerHeight;

      window.scrollTo({
        top: offset,
        behavior: 'smooth',
      });
    }
  };
  return (
    <footer  className="footerClass" id="contactusid">
      <div className="container" >
     <div className="footercontainer"><a href="#"><img src={logo}  alt="logo" /></a></div> 
      <div className="footercontainer">
      
    <div className="contactcontainer">
    
    <h2>Quick Links:</h2>
    {/* OLD a tag */}
    {/* <a href="#" onClick={(e) => scrollToSection('homeid',e)}>Home</a>
    <a href="#"onClick={(e) => scrollToSection('aboutusid',e)}>About us</a>
    <a href="#" onClick={(e) => scrollToSection('packageid',e)}>Umrah</a>
    <a href="#" onClick={(e) => scrollToSection('activityid',e)}>Hajj</a>
    <a href="#" onClick={(e) => scrollToSection('serviceid',e)}>Blogs</a> */}
     <a href="/" >Home</a>
    <a href="/gallerypage">About us</a>
    <a href="/umrahpage" >Umrah</a>
    <a href="/hajjpage" >Hajj</a>
    <a href="#" >Blogs</a>
      </div>
      </div>

    <div className="footercontainer">
    <div className="contactcontainer">
      <h2><FontAwesomeIcon icon={faEnvelope} /> Contact Us:</h2>
      <p>Company Address <FontAwesomeIcon icon={faMapMarker} /> :<br></br>
Shop No 04 , Madeena Complex, melekote Main Road Sadashivanagar Tumkur-572101<br></br><br></br>
 Mob <FontAwesomeIcon icon={faMobile} /> : <br></br>
95383 22223
97386 49714
</p>
    </div>

    </div>
    <div className="footercontainer">
      <div className="contactcontainer">
        <h2>Connect:</h2>
        <div className="connectdiv">
        <a href="https://web.whatsapp.com/send?phone=919538322223&text=Hi%0AI%20found%20it%2C%0A%0Aalkausartours%0A%0Ahttps%3A%2F%2Falkausartours.com" target="_blank" className="social-icon">
          <img src={whatsapp} alt="whatsapp"></img>
        </a>
        <a href="https://wa.me/9538322223" target="_blank" className="social-icon">
          <img src={facebook} alt="facebook"></img>
        </a>
        <a href="https://wa.me/9538322223" target="_blank" className="social-icon">
          <img src={instagram} alt="instagram"></img>
        </a>
        <a href="https://wa.me/9538322223" target="_blank" className="social-icon">
          <img src={twitter} alt="twitter"></img>
        </a>
        </div>
      </div>
    </div>

    {/* <div className="footercontainer">
    <div className="contactcontainer">
    <h2>our Partners:</h2>
    <img src={ethihad}  alt="mainimage" />
      </div>
      </div> */}
    </div>
    <div className="bottom">
    <p>&copy; 2024 AL Kausar Tours International. All rights reserved.</p>
    </div>
    
      
    </footer>
  );
};

export default FooterContent;