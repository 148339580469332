import './App.css';
import HeaderComponent from './js/header.js';
import MainContent from './js/maincontent.js';
import FooterContent from './js/footer.js';
import WhatsAppStickyIcon from './js/WhatsAppStickyIcon';
import HajjPage from './js/hajjpage.js';
import GalleryPageComponent from './js/gallerypage.js'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ParticaleBackground from'./js/particalcomp.js'
import ScrollToTopOnRouteChange from './js/ScrollToTopOnRouteChange.js';
import UmrahPage from './js/umrahpage.js';
import ZiyarathPage from './js/ziyarathpage.js';
import ZiyarathCostlyPage from './js/ziyarathcostlypackage.js';
import ZiyarathEconomyPage from './js/ziyaratheconomypackage.js';
import HajjLongPackagePage from './js/hajjlongpackage.js';
import HajjShortPackagePage from './js/hajjshortpackage.js';
import UmrahPackage1Page from './js/umrahpackage1.js';
import UmrahPackage2Page from './js/umrahpackage2.js';
import UmrahPackage3Page from './js/umrahpackage3.js';
import OtherTravelPackage from './js/otherpackage.js';
function App() {
  return (
    <div className="appdiv">
    <ParticaleBackground/>
 <HeaderComponent/>
 <Router>
 <ScrollToTopOnRouteChange />
      <Routes>
        
        <Route path="/" element={<MainContent/>} />
        <Route path="/hajjPage" element={<HajjPage/>} />
        <Route path="/umrahpage" element={<UmrahPage/>} />
        <Route path="/ziyarathpage" element={<ZiyarathPage/>} />
        <Route path="/ziyarathhighpackpage" element={<ZiyarathCostlyPage/>} />
        <Route path="/ziyaratheconomypackpage" element={<ZiyarathEconomyPage/>} />
        <Route path="/hajjlongpackage" element={<HajjLongPackagePage/>} />
        <Route path="/hajjshortpackage" element={<HajjShortPackagePage/>} />
        <Route path="/umrahpackage1" element={<UmrahPackage1Page/>} />
        <Route path="/umrahpackage2" element={<UmrahPackage2Page/>} />
        <Route path="/umrahpackage3" element={<UmrahPackage3Page/>} />
        <Route path="/othertravelpack" element={<OtherTravelPackage/>} />
        
        <Route path="/gallerypage" element={<GalleryPageComponent/>}/>
        
      </Routes>
    </Router>
    
    <FooterContent/>
    <WhatsAppStickyIcon />
    </div>
   
  );
}

export default App;