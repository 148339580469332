import React,  { useState, useRef  } from 'react';
import '../css/commencsspage.css';
import mainimagecomman from '../images/widekabbaimage.jpg';
import airticket1 from '../images/services/airticket.png';
import buffet from '../images/services/buffet.png';
import bus from '../images/services/bus.png';
import hotel from '../images/services/hotel.png';
import insurance from '../images/services/insurance.png';
import laundry from '../images/services/laundry.png';
import travelbag from '../images/services/travelbag.png';
import visa from '../images/services/visa.png';
import zamzam from '../images/services/zamzam.png';
import ziyarath from '../images/services/ziyarath.png';
import travelimage from '../images/travelimage.jpg';

const HajjShortPackagePage = () => {
  const [hajjshortpackageStates, setHajjShortPackageStates] = useState({
    pointtoremember: false,
    termsandconditions: false,
    inclusive: false,
    requireddocument: false,
    // Add more dropdown keys as needed
  });

  const toggleDropdown = (dropdown) => {
    setHajjShortPackageStates((prevState) => ({
      ...prevState,
      [dropdown]: !prevState[dropdown],
    }));
  };

  const closeDropdown = (dropdown) => {
    setHajjShortPackageStates((prevState) => ({
      ...prevState,
      [dropdown]: false,
    }));
  };
    return (
      <main className="commanPageContainer" >
          <div className="commanPageImageContainer">
            <img src={mainimagecomman} alt="no image found"></img>
            <div className="commanheader"><h1>Hajj</h1>
            <p>Hajj is a significant annual pilgrimage to Mecca, mandatory for Muslims who are physically and financially capable, symbolizing unity and devotion to Allah, following the footsteps of Prophet Abraham's legacy.  </p>
            </div>
          </div>
         
          <div className="commanPageContentContainer">
            <div className='commanPageMainContainer'>
            <h1>Hajj Short Package 2024</h1>
            <h2>NON-SHIFTING SHORT TOUR</h2>
            <h3>HAJJ PACKAGE FROM BANGALORE 2024</h3>
            <p>We have Hajj Short Package NON-SHIFTING SHORT TOUR as a short tour of the comman journey. In this short tour package, we aim to provide a hassle-free pilgrimage journey. Certainly, a Short comman Tour will provide you with the maximum services in the shortest time. Similarly, it will not take much of your time. Therefore, if you are in a busy schedule, this is the package to choose. In short, if you have a good budget and you are looking for a Short comman Tour, you have come to the right place.</p>
            <h3>Unique Features</h3>
            <p>There are some unique features of this package. Firstly, pilgrims are in the shortest package of comman. Secondly, the pilgrims will have MOUALLIM SERVICES AS PER THEIR PACKAGE. Thirdly, the transfers will be in luxurious private buses when in Mina and Arafat. Likewise, the Hotel in Makkah will be Hotel Snood Ajyad or a Similar Hotel-300 meters away from Haram, and in Madina, the Hotel will be Ritz Al Madinah (Full Board) or a Similar Hotel, 200 Metres away from Markaziya North Area. Most importantly, by promising you the Shortest comman Tour Package, we mean to give you the best possible facilities than any other tour operator or travel agency in 13 days.</p>
            <h3>Conclusion</h3>
            <p>Al Kausar Tours provides more premium comman Tour Packages for pilgrims with a higher budget. You can check out our official customer Google reviews. You can explore our Packages of Umrah. “Best comman Tours from Tumkur” is the most suitable title for Al Kausar Tours & Travels. One of our customers says that we provide the Best comman Tours from Tumkur for all types of budgets.</p>
            <h3>Recommendations:</h3>
            <div className="recommended">
              
            <a href="/hajjlongpackage">Hajj 37 Days Shifting Package 2024</a>
            <a href="/umrahpage">Umrah</a>
            <a href="/ziyarathpage">Ziyarath</a>
            </div>
            <p><strong>Departure Cities:</strong>Bangalore</p>
            <p><strong>SECTORS | AIRLINES :</strong> Bom –Jed–Bom | Saudi Airlines</p>
            <p><strong>Baggage Allowance:</strong>30 kg Check in Luggage + 07 kg Hand Luggage + 5 Ltr Zam Zam</p>
            <span className="theline"></span>
            {/* <h3>PRICING TOUR NO. HT-01</h3>
            <div className="amountcontainer">
            <div className="amount"><h4>FOUR BED SHARING</h4><br></br><p>₹ 8,22,000/-</p></div>
            <div className="amount"><h4>FOUR BED SHARING</h4><br></br><p>₹ 8,22,000/-</p></div>
            <div className="amount"><h4>FOUR BED SHARING</h4><br></br><p>₹ 8,22,000/-</p></div>
            </div>
            <span className="theline"></span> */}
            <h3>Departure:</h3>
            <p><strong>DATE (BOM) :</strong>June 6th to 9th</p>

            {/* <span className="theline"></span> */}
            <div className="purchaseBanner"><span className="purchaseSpan"><strong>Pre-Book</strong></span></div>
            <p><strong>Meal:</strong>Premium Platinum Menu | Full Board – Food cooked by our Indian Chefs served in Buffet style.</p>
            <p><strong>Duration:</strong>  20 days</p>
            <p><strong>Transfers:</strong> Transportation will be provided in luxurious buses.</p>

            <p><strong>comman Camp:</strong> Category-D</p>

            <div className="hotelDetailsContainer">

              <div className="hotelDetails"> 
              <strong>HOTEL MAKKAH / STAY IN MAKKAH :</strong>
              <p>Hotel Snood Ajyad or a Similar Hotel.</p>
              <p> <strong>DISTANCE :</strong> 300 Mtrs from Haram.</p>
             <p> <strong>CHECK IN DATE :</strong>Will be shared</p>
             <p> <strong>CHECK OUT DATE :</strong> Will be shared</p>
             
              </div>
              <div className="hotelDetails"> 
              <strong>HOTEL MAKKAH / STAY IN MAKKAH :</strong>
              <p>Hotel Snood Ajyad or a Similar Hotel.</p>
              <p> <strong>DISTANCE :</strong> 300 Mtrs from Haram.</p>
             <p> <strong>CHECK IN DATE :</strong>Will be shared</p>
             <p> <strong>CHECK OUT DATE :</strong> Will be shared</p>
              </div>
            </div>
            <span className="theline"></span>
            <u>Note: Package details are tentative and subject to change without any prior notice.</u>
            <div>
              
      <h2 onClick={() => toggleDropdown('inclusive')}>
      {hajjshortpackageStates.inclusive ? '-' : '+'} Inclusive: 
      </h2>
      <ul className={`subcategory-list ${hajjshortpackageStates.inclusive ? 'open' : ''}`}>
        <li> Return Air Ticket(Via Flight).</li>
        <li> Hajj Visa With All Insurance.</li>
        <li> Madinah and Makkah Hotel.</li>
        <li> 6 Nights GOLDEN MANZIL AL FALAH</li>
        <li> 14 Days Barakah MOWADDA AJYAD INCLUDING HAJJ</li>
        <li> Meena Muzdalifa Arafat</li>
        <li> 24*7 Assistance</li>
        
      </ul>
      <h2 onClick={() => toggleDropdown('requireddocument')}>
      {hajjshortpackageStates.requireddocument ? '-' : '+'} RequiredDocument's: 
      </h2>
      <ul className={`subcategory-list ${hajjshortpackageStates.requireddocument ? 'open' : ''}`}>
        <li> Passport with a minimum validity of 6 months.</li>
        <li> 6 Photos with visa size with white background</li>
        <li> Aadhar and PAN card.</li>
        <li> Covid Vaccine Certificate.</li>
        
      </ul>
      <h2 onClick={() => toggleDropdown('pointtoremember')}>
      {hajjshortpackageStates.pointtoremember ? '-' : '+'} Points to remember:
      </h2>
      <ul className={`subcategory-list ${hajjshortpackageStates.pointtoremember ? 'open' : ''}`}>
        <li> GST & TCS will be applicable.</li>
        <li> Travel agency is not responsible for the loss of luggage, if any.</li>
        <li> Extensions of flight routes will be charged and must be confirmed at the time of booking.</li>
        <li> Original passports must be submitted to our travel agency for visa endorsement before the deadline dates provided by us.</li>
        <li> Hotels and flight schedules are subject to change without prior notice.</li>
      </ul>

      <h2 onClick={() => toggleDropdown('termsandcondtions')}>
      {hajjshortpackageStates.termsandcondtions ? '-' : '+'} Terms and Conditions: 
      </h2>
      <ul className={`subcategory-list ${hajjshortpackageStates.termsandcondtions ? 'open' : ''}`}>
        <li> All bookings are subject to license approved by MOMA and the quota allotted by MOMA.</li>
        <li> If any fluctuation in taxes/currency by the Indian or Saudi Government, passengers have to bear the difference.</li>
        
      </ul>

      {/* Add more dropdown sections as needed */}
    </div>

            </div>
           
          </div>
          

          {/* <div className="services" id="serviceid">
   <h2>Our Services</h2>
   <div className="servicescontainer">
   
   <img src={zamzam} alt="logo"></img> 
   <img src={buffet} alt="logo"></img> 
   <img src={bus} alt="logo"></img> 
   <img src={insurance} alt="logo"></img> 
   <img src={laundry} alt="logo"></img> 
   <img src={travelbag} alt="logo"></img> 
  <img src={visa} alt="logo"></img> 
   <img src={airticket1} alt="logo"></img> 
   <img src={ziyarath} alt="logo"></img>
   <img src={hotel} alt="logo"></img> 
   </div>
  
   
   </div>  */}
 <h2>Package Include:</h2>
   <marquee className="servicesMovingContainer">
   <img src={airticket1} alt="logo"></img>
   <img src={visa} alt="logo"></img>  
   <img src={insurance} alt="logo"></img>
   <img src={buffet} alt="logo"></img>  
   <img src={hotel} alt="logo"></img> 
   <img src={ziyarath} alt="logo"></img>
   <img src={bus} alt="logo"></img> 
   <img src={laundry} alt="logo"></img> 
   <img src={zamzam} alt="logo"></img> 
   <img src={travelbag} alt="logo"></img> 
  
  
  
   
  
  
   
   
   </marquee>


   


      </main>
    );
  };
  
  export default HajjShortPackagePage;