import React,  { useState, useRef  } from 'react';
import {Link } from 'react-router-dom';
import '../css/commencsspage.css';
import mainimagecomman from '../images/widekabbaimage.jpg';
import airticket1 from '../images/services/airticket.png';
import buffet from '../images/services/buffet.png';
import bus from '../images/services/bus.png';
import hotel from '../images/services/hotel.png';
import insurance from '../images/services/insurance.png';
import laundry from '../images/services/laundry.png';
import travelbag from '../images/services/travelbag.png';
import visa from '../images/services/visa.png';
import zamzam from '../images/services/zamzam.png';
import ziyarath from '../images/services/ziyarath.png';
import travelimage from '../images/travelimage.jpg';
import kaaba4 from '../images/kaaba4.jpg';
import kaaba3 from '../images/kaaba3.jpg';
import kaaba2 from '../images/kaaba2.jpg';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UmrahPackage1Page = () => {
  const [umrahpackage1States, setUmrahpacakge1States] = useState({
    pointtoremember: false,
    termsandconditions: false,
    schools: false,
    computers: false,
    // Add more dropdown keys as needed
  });

  const toggleDropdown = (dropdown) => {
    setUmrahpacakge1States((prevState) => ({
      ...prevState,
      [dropdown]: !prevState[dropdown],
    }));
  };

  const closeDropdown = (dropdown) => {
    setUmrahpacakge1States((prevState) => ({
      ...prevState,
      [dropdown]: false,
    }));
  };
    return (
      <main className="commanPageContainer" >
       
          <div className="commanPageImageContainer">
            <img src={mainimagecomman} alt="no image found"></img>
            <div className="commanheader"><h1>Umrah</h1>
            <p>comman is a significant annual pilgrimage to Mecca, mandatory for Muslims who are physically and financially capable, symbolizing unity and devotion to Allah, following the footsteps of Prophet Abraham's legacy.  </p>
            </div>
          </div>
         
          <div className="commanPageContentContainer">
         
            <div className='commanPageMainContainer'>
            <h1>Umrah Economy Package 2024</h1>
            <h3>Umrah PACKAGE FROM BANGALORE 2024</h3>
             
            <p>Al Kausar Tours provides more premium comman Tour Packages for pilgrims with a higher budget. You can check out our official customer Google reviews. You can explore our Packages of Umrah and Hajj. “Best comman Tours from Tumkur” is the most suitable title for Al Kausar Tours & Travels. One of our customers says that we provide the Best comman Tours from Tumkur for all types of budgets.</p>
            <h3>Recommendations:</h3>
            <div className="recommended">
              
            <a href="/umrahpackage2">Umrah Premium Package 2024</a>
            <a href="/umrahpackage3">Umrah Deluxe Package 2024</a>
            <a href="/hajjpage">Hajj</a>
            <a href="/ziyarathpage">Ziyarath</a>
            </div>
            <p><strong>Departure Cities:</strong>Bangalore</p>
            <p><strong>SECTORS | AIRLINES :</strong> Bom –Jed–Bom | Saudi Airlines or similar</p>
            <p><strong>Baggage Allowance:</strong>30 kg Check in Luggage + 07 kg Hand Luggage + 5 Ltr Zam Zam</p>
            {/* <span className="theline"></span>
            <h3>PRICING TOUR NO. HT-01</h3>
            <div className="amountcontainer">
            <div className="amount"><h4>FOUR BED SHARING</h4><br></br><p>₹ 8,22,000/-</p></div>
            <div className="amount"><h4>FOUR BED SHARING</h4><br></br><p>₹ 8,22,000/-</p></div>
            <div className="amount"><h4>FOUR BED SHARING</h4><br></br><p>₹ 8,22,000/-</p></div>
            </div> */}
            <span className="theline"></span>
            <h3>Departure:</h3>
            <p><strong>DATE (BOM) :</strong>Every Month Last</p>

            {/* <span className="theline"></span> */}
            <div className="purchaseBanner"><span className="purchaseSpan"><strong>Pre-Book</strong></span></div>
            <p><strong>Meal:</strong> Silver Menu | Full Board – Food cooked by our Indian Chefs served in Buffet style.</p>
            <p><strong>Duration:</strong>  15 days</p>
            <p><strong>Transfers:</strong> Transportation will be provided in luxurious buses.</p>

            {/* <p><strong>comman Camp:</strong> Category-D</p>

            <div className="hotelDetailsContainer">

              <div className="hotelDetails"> 
              <strong>HOTEL MAKKAH / STAY IN MAKKAH :</strong>
              <p>Hotel Snood Ajyad or a Similar Hotel.</p>
              <p> <strong>DISTANCE :</strong> 300 Mtrs from Haram.</p>
             <p> <strong>CHECK IN DATE :</strong>Will be shared</p>
             <p> <strong>CHECK OUT DATE :</strong> Will be shared</p>
             
              </div>
              <div className="hotelDetails"> 
              <strong>HOTEL MAKKAH / STAY IN MAKKAH :</strong>
              <p>Hotel Snood Ajyad or a Similar Hotel.</p>
              <p> <strong>DISTANCE :</strong> 300 Mtrs from Haram.</p>
             <p> <strong>CHECK IN DATE :</strong>Will be shared</p>
             <p> <strong>CHECK OUT DATE :</strong> Will be shared</p>
              </div>
            </div> */}
            <span className="theline"></span>
            <u>Note: Package details are tentative and subject to change without any prior notice.</u>
            <div>
      <h2 onClick={() => toggleDropdown('pointtoremember')}>
      {umrahpackage1States.pointtoremember ? '-' : '+'} Points to remember:
      </h2>
      <ul className={`subcategory-list ${umrahpackage1States.pointtoremember ? 'open' : ''}`}>
        <li> GST & TCS will be applicable.</li>
        <li> Travel agency is not responsible for the loss of luggage, if any.</li>
        <li> Extensions of flight routes will be charged and must be confirmed at the time of booking.</li>
        <li> Original passports must be submitted to our travel agency for visa endorsement before the deadline dates provided by us.</li>
        <li> Hotels and flight schedules are subject to change without prior notice.</li>
      </ul>

      <h2 onClick={() => toggleDropdown('termsandcondtions')}>
      {umrahpackage1States.termsandcondtions ? '-' : '+'} Terms and Conditions: 
      </h2>
      <ul className={`subcategory-list ${umrahpackage1States.termsandcondtions ? 'open' : ''}`}>
        <li> All bookings are subject to license approved by MOMA and the quota allotted by MOMA.</li>
        <li> If any fluctuation in taxes/currency by the Indian or Saudi Government, passengers have to bear the difference.</li>
        
      </ul>

      {/* Add more dropdown sections as needed */}
    </div>

            </div>
           
          </div>
          

          {/* <div className="services" id="serviceid">
   <h2>Our Services</h2>
   <div className="servicescontainer">
   
   <img src={zamzam} alt="logo"></img> 
   <img src={buffet} alt="logo"></img> 
   <img src={bus} alt="logo"></img> 
   <img src={insurance} alt="logo"></img> 
   <img src={laundry} alt="logo"></img> 
   <img src={travelbag} alt="logo"></img> 
  <img src={visa} alt="logo"></img> 
   <img src={airticket1} alt="logo"></img> 
   <img src={ziyarath} alt="logo"></img>
   <img src={hotel} alt="logo"></img> 
   </div>
  
   
   </div>  */}
 <h2>Package Include:</h2>
   <marquee className="servicesMovingContainer">
   <img src={airticket1} alt="logo"></img>
   <img src={visa} alt="logo"></img>  
   <img src={insurance} alt="logo"></img>
   <img src={buffet} alt="logo"></img>  
   <img src={hotel} alt="logo"></img> 
   <img src={ziyarath} alt="logo"></img>
   <img src={bus} alt="logo"></img> 
   <img src={laundry} alt="logo"></img> 
   <img src={zamzam} alt="logo"></img> 
   <img src={travelbag} alt="logo"></img> 
  
  
  
   
  
  
   
   
   </marquee>


   


      </main>
    );
  };
  
  export default UmrahPackage1Page;